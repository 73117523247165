import { stringify } from 'query-string';
import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_SAVED_PROJECT_COUNTS = 'GET_SAVED_PROJECT_COUNTS'
const GET_SAVED_PROJECT_COUNTS_SUCCESS = 'GET_SAVED_PROJECT_COUNTS_SUCCESS'
const GET_SAVED_PROJECT_COUNTS_RESET = 'GET_SAVED_PROJECT_COUNTS_RESET'
const GET_SAVED_PROJECT_COUNTS_FAILURE = 'GET_SAVED_PROJECT_COUNTS_FAILURE'

const GET_SAVED_PROJECT = 'GET_SAVED_PROJECT'
const GET_SAVED_PROJECT_MORE = 'GET_SAVED_PROJECT_MORE'
const GET_SAVED_PROJECT_SUCCESS = 'GET_SAVED_PROJECT_SUCCESS'
const GET_SAVED_PROJECT_MORE_SUCCESS = 'GET_SAVED_PROJECT_MORE_SUCCESS'
const GET_SAVED_PROJECT_RESET = 'GET_SAVED_PROJECT_RESET'
const GET_SAVED_PROJECT_FAILURE = 'GET_SAVED_PROJECT_FAILURE'

const UPDATE_PI_STATUS = 'UPDATE_PI_STATUS'
const UPDATE_PI_STATUS_SUCCESS = 'UPDATE_PI_STATUS_SUCCESS'
const UPDATE_PI_STATUS_RESET = 'UPDATE_PI_STATUS_RESET'
const UPDATE_PI_STATUS_FAILURE = 'UPDATE_PI_STATUS_FAILURE'

const GET_PREFRERRED_SITE = 'GET_PREFRERRED_SITE'
const GET_PREFRERRED_SITE_SUCCESS = 'GET_PREFRERRED_SITE_SUCCESS'
const GET_PREFRERRED_SITE_RESET = 'GET_PREFRERRED_SITE_RESET'
const GET_PREFRERRED_SITE_FAILURE = 'GET_PREFRERRED_SITE_FAILURE'

const CHANGE_PREFRERRED_SITE = 'CHANGE_PREFRERRED_SITE'
const CHANGE_PREFRERRED_SITE_SUCCESS = 'CHANGE_PREFRERRED_SITE_SUCCESS'
const CHANGE_PREFRERRED_SITE_RESET = 'CHANGE_PREFRERRED_SITE_RESET'
const CHANGE_PREFRERRED_SITE_FAILURE = 'CHANGE_PREFRERRED_SITE_FAILURE'

const CREATE_VERSION = 'CREATE_VERSION'
const CREATE_VERSION_SUCCESS = 'CREATE_VERSION_SUCCESS'
const CREATE_VERSION_RESET = 'CREATE_VERSION_RESET'
const CREATE_VERSION_FAILURE = 'CREATE_VERSION_FAILURE'

const GET_QUESTIONS = 'GET_QUESTIONS'
const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS'
const GET_QUESTIONS_MORE_LIST = 'GET_QUESTIONS_MORE_LIST'
const GET_QUESTIONS_RESET = 'GET_QUESTIONS_RESET'
const GET_QUESTIONS_FAILURE = 'GET_QUESTIONS_FAILURE'
const GET_QUESTIONS_MORE_LIST_SUCCESS = 'GET_QUESTIONS_MORE_LIST_SUCCESS'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getSavedProjectCountsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SAVED_PROJECT_COUNTS,
    url: `${process.env.apiUrl}v0/project/getprojectResultCount`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getSavedProjectCountsResetAction = () => ({
  type: GET_SAVED_PROJECT_COUNTS_RESET,
})

export function getSavedProjectCountsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_SAVED_PROJECT_COUNTS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SAVED_PROJECT_COUNTS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SAVED_PROJECT_COUNTS_RESET: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_SAVED_PROJECT_COUNTS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getSavedProjectAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SAVED_PROJECT,
    url: `${process.env.apiUrl}v0/project/getProjectResult`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getSavedProjectResetAction = () => ({
  type: GET_SAVED_PROJECT_RESET,
})

export const getSavedProjectMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SAVED_PROJECT_MORE,
    url: `${process.env.apiUrl}v0/project/getProjectResult`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getSavedProjectReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_SAVED_PROJECT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SAVED_PROJECT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SAVED_PROJECT_MORE_SUCCESS: {
      return {
        data: {
          data: [{
            ...state.data.data[0],
            result_data: [
              ...state.data.data[0].result_data,
              ...action.payload.data[0].result_data,
            ],
          }],
          total: action.payload.total,
          totalCenters: action.payload.totalCenters,
          totalPiCount: action.payload.totalPiCount,
          comments: action.payload.comments,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SAVED_PROJECT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_SAVED_PROJECT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

export const updatePiStatusAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.updateParams,
  meta: {
    actionType: UPDATE_PI_STATUS,
    url: `${process.env.apiUrl}v0/project/statusUpdate?project_id=${payload.project_id}`,
    method: 'PUT',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const createNewVersionResetAction = () => ({
  type: CREATE_VERSION_RESET,
})

export const createNewVersionAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.updateParams,
  meta: {
    actionType: CREATE_VERSION,
    url: `${process.env.apiUrl}v0/projects/createVersion?project_id=${payload.project_id}`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true,
  message: 'New version created', // show notification on success
  hideNotification: false, // hide notification on error
})

export const updatePiForAllAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: UPDATE_PI_STATUS,
    url: `${process.env.apiUrl}v0/project/statusUpdate?project_id=${payload.project_id}&filters=${JSON.stringify(payload.filters)}`,
    method: 'PUT',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const updatePiStatusResetAction = () => ({
  type: UPDATE_PI_STATUS_RESET,
})

export function updatePiStatusReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_PI_STATUS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_PI_STATUS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_PI_STATUS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case UPDATE_PI_STATUS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

export const getPreferredSiteAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PREFRERRED_SITE,
    url: `${process.env.apiUrl}v0/search/preferredSite`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getPreferredSiteResetAction = () => ({
  type: GET_PREFRERRED_SITE_RESET,
})

export function getPreferredSiteReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PREFRERRED_SITE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PREFRERRED_SITE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PREFRERRED_SITE_RESET: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_PREFRERRED_SITE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const changePreferredSiteAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: CHANGE_PREFRERRED_SITE,
    url: `${process.env.apiUrl}/v0/search/editpi?pi_id=${payload.pi_id}&project_id=${payload.project_id}&isProject=${payload.isProject}`,
    method: 'POST',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true,
  message: 'preferred site changed', // show notification on success
  hideNotification: false, // hide notification on error
})

export const changePreferredSiteResetAction = () => ({
  type: CHANGE_PREFRERRED_SITE_RESET,
})

export function changePreferredSiteReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case CHANGE_PREFRERRED_SITE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CHANGE_PREFRERRED_SITE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CHANGE_PREFRERRED_SITE_RESET: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CHANGE_PREFRERRED_SITE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function createNewVersionReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case CREATE_VERSION: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_VERSION_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CREATE_VERSION_RESET: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CREATE_VERSION_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// GET Question

export const getQuestionAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_QUESTIONS,
    url: `${process.env.apiUrl}v0/projects/getQuestions`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  // showNotification: false, // show notification on success
  // hideNotification: false, // hide notification on error
})

export const getQuestionDataMoreAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_QUESTIONS_MORE_LIST,
      url: `${process.env.apiUrl}v0/projects/getQuestions`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const getQuestionResetAction = () => ({
  type: GET_QUESTIONS_RESET,
})

export function getQuestionReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_QUESTIONS: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_QUESTIONS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_QUESTIONS_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_QUESTIONS_MORE_LIST_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    default:
      return state
  }
}

// Add  Questions

const ADD_QUESTIONS = 'ADD_QUESTIONS';
const ADD_QUESTIONS_CANCEL = 'ADD_QUESTIONS_CANCEL';
const ADD_QUESTIONS_RESET = 'ADD_QUESTIONS_RESET';
const ADD_QUESTIONS_SUCCESS = 'ADD_QUESTIONS_SUCCESS';
const ADD_QUESTIONS_FAILURE = 'ADD_QUESTIONS_FAILURE';

// Auto Complete cancel action
export const addQuestionsCancelAction = () => ({
  type: ADD_QUESTIONS_CANCEL,
});

// Auto Complete action
// export const addQuestionsAction = (payload) => ({
//   type: ADD_QUESTIONS,
//   payload,
// });

export const addQuestionsAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: ADD_QUESTIONS,
      url: `${process.env.apiUrl}v0/projects/addQuestion`,
      method: 'POST',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: meta.status_flag === 'add' ? 'Question Added Successfully' : 'Question Copied Successfully',
    hideNotification: false,
    // customErrMessage: 'Warning : SITE already exists with this name',
  })
}

// Add project reset action
export const addQuestionsResetAction = () => ({
  type: ADD_QUESTIONS_RESET,
});

export function addQuestionsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADD_QUESTIONS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case ADD_QUESTIONS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case ADD_QUESTIONS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      };
    }
    case ADD_QUESTIONS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

// update question

const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS';
const UPDATE_QUESTIONS_RESET = 'UPDATE_QUESTIONS_RESET';
const UPDATE_QUESTIONS_SUCCESS = 'UPDATE_QUESTIONS_SUCCESS';
const UPDATE_QUESTIONS_FAILURE = 'UPDATE_QUESTIONS_FAILURE';

export const updateQuestionsResetAction = () => ({
  type: UPDATE_QUESTIONS_RESET,
})

export const updateQuestionsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: UPDATE_QUESTIONS,
    url: `${process.env.apiUrl}v0/projects/updateQuestion?innoplexus_id=${payload.params.innoplexus_id}`,
    method: 'PUT',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true,
  message: 'Question Updated Successfully',
  hideNotification: false,
})

export function updateQuestionsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_QUESTIONS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_QUESTIONS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_QUESTIONS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case UPDATE_QUESTIONS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

// Delete questions

const DELETE_QUESTIONS = 'DELETE_QUESTIONS'
const DELETE_QUESTIONS_SUCCESS = 'DELETE_QUESTIONS_SUCCESS'
const DELETE_QUESTIONS_FAILURE = 'DELETE_QUESTIONS_FAILURE'
const DELETE_QUESTIONS_RESET = 'DELETE_QUESTIONS_RESET'

export const deleteQuestionsAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: DELETE_QUESTIONS,
      url: `${process.env.apiUrl}/v0/projects/deleteQuestion?${stringify(payload)}`,
      method: 'DELETE',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Question deleted successfully',
    hideNotification: false, // hide notification on error
  })
}

export function deleteQuestionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_QUESTIONS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_QUESTIONS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_QUESTIONS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_QUESTIONS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// Add response

const ADD_RESPONSE = 'ADD_RESPONSE';
const ADD_RESPONSE_CANCEL = 'ADD_RESPONSE_CANCEL';
const ADD_RESPONSE_RESET = 'ADD_RESPONSE_RESET';
const ADD_RESPONSE_SUCCESS = 'ADD_RESPONSE_SUCCESS';
const ADD_RESPONSE_FAILURE = 'ADD_RESPONSE_FAILURE';

// Auto Complete cancel action
export const addResponseCancelAction = () => ({
  type: ADD_RESPONSE_CANCEL,
});

// Auto Complete action
// export const addQuestionsAction = (payload) => ({
//   type: ADD_QUESTIONS,
//   payload,
// });

export const addResponseAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body.data,
    meta: {
      actionType: ADD_RESPONSE,
      url: `${process.env.apiUrl}v0/projects/addResponse?question_id=${(payload.param.question_id)}`,
      method: 'POST',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: 'Response Added Successfully',
    hideNotification: false,
    // customErrMessage: 'Warning : SITE already exists with this name',
  })
}

// Add project reset action
export const addRespnseResetAction = () => ({
  type: ADD_RESPONSE_RESET,
});

export function addResponseReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADD_RESPONSE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case ADD_RESPONSE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case ADD_RESPONSE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      };
    }
    case ADD_RESPONSE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

// get additional details

const GET_QUESTIONS_ADDITIONAL_DETAILS = 'GET_QUESTIONS_ADDITIONAL_DETAILS'
const GET_QUESTIONS_ADDITIONAL_DETAILS_SUCCESS = 'GET_QUESTIONS_ADDITIONAL_DETAILS_SUCCESS'
const GET_QUESTIONS_ADDITIONAL_DETAILS_RESET = 'GET_QUESTIONS_ADDITIONAL_DETAILS_RESET'
const GET_QUESTIONS_ADDITIONAL_DETAILS_FAILURE = 'GET_QUESTIONS_ADDITIONAL_DETAILS_FAILURE'

export const getQuestionAdditionalDetailsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_QUESTIONS_ADDITIONAL_DETAILS,
    url: `${process.env.apiUrl}v0/projects/questionAdditonalDetails`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getQuestionAdditionalDetailsResetAction = () => ({
  type: GET_QUESTIONS_ADDITIONAL_DETAILS_RESET,
})

export function getQuestionAdditionalDetailsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_QUESTIONS_ADDITIONAL_DETAILS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_QUESTIONS_ADDITIONAL_DETAILS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_QUESTIONS_ADDITIONAL_DETAILS_RESET: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_QUESTIONS_ADDITIONAL_DETAILS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// update question

const UPDATE_RESPONSE = 'UPDATE_RESPONSE';
const UPDATE_RESPONSE_RESET = 'UPDATE_RESPONSE_RESET';
const UPDATE_RESPONSE_SUCCESS = 'UPDATE_RESPONSE_SUCCESS';
const UPDATE_RESPONSE_FAILURE = 'UPDATE_RESPONSE_FAILURE';

export const updateResponseResetAction = () => ({
  type: UPDATE_RESPONSE_RESET,
})

export const updateResponseAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: UPDATE_RESPONSE,
    url: `${process.env.apiUrl}v0/project/updateResponse?innoplexus_id=${payload.params.innoplexus_id}`,
    method: 'PUT',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true,
  message: 'Response Updated Successfully',
  hideNotification: false,
})

export function updateResponseReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_RESPONSE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_RESPONSE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_RESPONSE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case UPDATE_RESPONSE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

// Delete questions

const DELETE_RESPONSE = 'DELETE_RESPONSE'
const DELETE_RESPONSE_SUCCESS = 'DELETE_RESPONSE_SUCCESS'
const DELETE_RESPONSE_FAILURE = 'DELETE_RESPONSE_FAILURE'
const DELETE_RESPONSE_RESET = 'DELETE_RESPONSE_RESET'

export const deleteResponseAction = (payload, meta = {}) => {
  console.log('payload', payload)
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: DELETE_RESPONSE,
      url: `${process.env.apiUrl}/v0/projects/deleteResponse?${stringify(payload)}`,
      method: 'DELETE',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Response deleted successfully',
    hideNotification: false, // hide notification on error
  })
}

export function deleteResponseReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_RESPONSE: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_RESPONSE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_RESPONSE_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_RESPONSE_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
